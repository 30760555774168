import React from 'react';
import { hydrate, render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import './index.scss';
import { registerComponent } from "./service/common/registerComponents";
import HeroAndFooter from "./sections/HeroAndFooter";

// Polyfill Object.hasOwn for react-markdown with react-snap
// From : https://github.com/remarkjs/react-markdown/issues/772
if (!Object.hasOwn) {
    Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
}

//registerComponent('example', ExampleComponent);
//registerCallableComponent('joinBetaProgram', ModalExampleJoinBetaProgram);

// registerBlogComponents(Article, ArticlesList, Author, Category); // Default is already setted
// registerDocumentationComponents(Documentation, DocumentationMenu); // Default is already setted

registerComponent('HeroAndFooter', HeroAndFooter);

const rootElement = document.getElementById('root') as HTMLElement;

if (rootElement.hasChildNodes()) {
    hydrate((<RouterProvider router={router} />) as any, rootElement);
} else {
    render((<RouterProvider router={router} />) as any, rootElement);
}
