import React from 'react';
import Highlighter from './components/highlighter';
import { Link } from 'react-router-dom';

export default function HeroAndFooter() {
  return (
    <div className="relative isolate overflow-hidden bg-[#FCF5EF]">
      <div className="mx-auto max-w-[1240px] px-6 lg:px-8 mt-8 sm:mt-16 lg:mt-16">
          <nav className={"flex justify-between mb-24 max-[1050px]:mb-2"}>
            <img src={"/logo.svg"} alt={"Logo Notifizz"}/>
            <Link to={"https://app.notifizz.com/admins/signin"} className={"shadow-[0px_4px_11px_0px_#6D5EE03D] px-12 py-4 rounded-lg"}>Login</Link>
          </nav>
          <div className={"flex justify-between gap-8 mb-24 max-[1050px]:flex-col"}>
            <div className={"mt-16 flex gap-6 flex-col max-[1050px]:text-center max-[1050px]:mt-8"}>
              <div className={"bg-white rounded-[74px] px-4 py-1 w-fit  max-[1050px]:m-auto"}>
                <p className={"bg-gradient-to-r from-[#DB1783] to-[#F97316] bg-clip-text text-transparent text-[15px]"}>NOTIFICATION CENTER</p></div>
              <h1 className="mt-34 tracking-normal text-[48px] font-medium rmc-text-base-1 leading-[60px]">
                <Highlighter colored={true}>Grow your SAAS faster with a <span className='bg-gradient-to-r from-[#DB1783] to-[#F97316] bg-clip-text text-transparent'>Notification center</span></Highlighter>
              </h1>
              <p className={"text-[#6F6C90] text-[18px]"}>Give to Market and product team the full control of notification journey. Optimize it. Enrich it. Convert more, Sell more.</p>
              <div className={"flex"}>
                <Link to={"https://app.notifizz.com/admins/signup"} className={"bg-[#DD1F7B] text-white px-8 py-4 rounded-lg  max-[1050px]:m-auto"}>⚡️  Start sending notifications</Link>
              </div>
            </div>
            <img src={"/landing.svg"} alt={"Notifizz Process"}/>
          </div>
<footer className={"bg-white flex justify-between px-8 py-6 rounded-3xl mb-16 max-[1050px]:px-4 max-[1050px]:py-3"}>
  <div className={"flex flex-col items-baseline gap-1"}>
    <img src={"/logo.svg"} alt={"Notifizz logo"} className={"h-[30px]"}/>
    <p className={"text-[#6F6C90]"}>Grow your SAAS faster</p>
  </div>
  <div className={"flex flex-col items-baseline gap-1 max-[1050px]:items-center"}>
    <p className={"bg-gradient-to-r from-[#DB1783] to-[#F97316] bg-clip-text text-transparent"}>MORE</p>
    <p className={"text-center"}><Link to={"https://uniqrate.com/"} className={"text-[#6F6C90]"}>Built by Uniqrate</Link></p>
  </div>

</footer>

      </div>
    </div>
  );
}
